html, body {
  position: relative;
  margin: 0;
  border: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
div.AppLoader{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

section.header{
  height: 100vh;
}