@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif!important;
}

html, body {
  position: relative;
  margin: 0;
  border: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
div.AppLoader{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

section.header{
  height: 100vh;
}
